<template>
	<b-card title="FAQ Content List">
		<b-row class="align-items-center">
			<b-col cols="12" md="6">
				<router-link
					v-if="filter.category_uuid"
					:to="'/create-faq-content' +'/?' + filter.category_uuid"
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Create FAQ Content
				</router-link>
				<router-link
					v-else
					to="/create-faq-content"
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Create FAQ Content
				</router-link>
			</b-col>
			<b-col
				cols="12"
				md="6"
			>
				<div class="form-group">
					<label>FAQ Category</label>
					<select 
						class="form-control"
						v-model="filter.category_uuid"
						@change="filterFaq"
					>
						<option value="">-- Pilih FAQ Category --</option>
						<option 
							v-for="category in faqsCategory" 
							:key="category.uuid"
							:value="category.uuid"
						>
							{{ category.name }}
						</option>
					</select>
				</div>
			</b-col>
		</b-row>
		<b-table 
			striped 
			hover 
			:items="faqsContent"
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ (currentPage - 1) * 10 + index + 1 }}
			</template>	

			<template v-slot:cell(position)="{ item }">
				<input 
					type="number"
					class="form-control"
					placeholder="Masukan angka disini"
					@change="faqPosition(item.uuid, $event.target.value)"
				>
			</template>	
			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<router-link
						:to="'/edit-faq-content/'+ item.uuid"
					>
						<b-badge 
							variant="warning"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-edit-icon`"
								icon="EditIcon"
								class="cursor-pointer"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Edit Data'"
							/>
						</b-badge>
					</router-link>

					<b-badge 
						variant="danger"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-delete-icon`"
							icon="TrashIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							@click="deleteItem(item.uuid)"
						/>
					</b-badge>
					
				</div>
			</template>
		</b-table>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getDataContent"
					/>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBModal,
	VBTooltip,
	BFormSelect
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol,
		BFormSelect
	},
	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			result:{},
			fields: ['no', 'title', 'position', 'actions'],
			faqsContent: [],
			faqsCategory: [],
			filter: {
				keyword: '',
				category_uuid: '',
			},
			isLoading: false,
		}
	},
	computed: {
		rows() {
			return this.faqsCategory.length
		}
	},
	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	methods: {
		faqPosition(uuid, value) {
			this.position[index] = value
			this.$http.post('admin/faqs/sort', {
				uuid : uuid,
				position: value,
			})
			.then(response => {
				this.getDataContent()
				successNotification(this, 'Success', 'Faq Content berhasil diurutkan!')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', 'Ada kesalahan teknis')
				}
			})
		},
		filterFaq(e) {
			let categoryUuid = e.target.value
			this.filter.category_uuid = categoryUuid
			this.$http.get('admin/faqs?category_uuid=' + categoryUuid)
			.then(response => {
				this.getDataContent(this.currentPage)
			})
		},
		getDataCategory() {
			this.$http.get('admin/faq-categories')
			.then(response => {
				this.faqsCategory = response.data.data.data
			})
		},
		getDataContent(page) { //page = 1
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/faqs', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.faqsContent = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
		deleteItem(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Faq ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/faqs/' + item)
					this.getDataContent(this.currentPage)
					successNotification(this, 'Success', 'Faq berhasil dihapus!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},
	created() {
		this.getDataContent()
		this.getDataCategory()
	}
}
</script>